import React from 'react'

import Layout from '../layout/Layout';
import UserInputSection from '../components/inputs/UserInputSection'


const Homepage = () => {
  return (
    <Layout>
        <div className="container">
        <div className="row">
          <div className="col-md-6">
            <UserInputSection />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Homepage;